import Reel from '../components/reel'

export default function Standup() {
    return (
      <div className='wrapper'>
      <Reel 
        youtubeId='_7y0dY5M0l4'
        title=''>
      </Reel>
        <Reel 
          vimeoId="101355259?h=d3c1ecda49"
          title=''>
        </Reel>
    </div>
    );
  }